<template>
  <div class="login">
    <div style="height: 5.625rem"></div>
    <div class="login_box">
      <div class="login_box_cont">
        <div class="login_box_cont_title" v-if="type == 1 || type == 2">登录</div>
        <div class="login_box_cont_title" v-if="type == 3">注册</div>
        <div class="login_box_cont_title" v-if="type == 4 && typetext == 'loginpass'">
          修改密码
        </div>
        <div class="login_box_cont_title" v-if="type == 4 && typetext != 'loginpass'">
          忘记密码
        </div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="phone" v-if="type == 1">
            <el-input
              type="number"
              v-model="ruleForm.phone"
              autocomplete="off"
              placeholder="手机号"
              prefix-icon="el-icon-mobile-phone"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone2" v-else>
            <el-input
              type="number"
              v-model="ruleForm.phone2"
              autocomplete="off"
              placeholder="手机号"
              prefix-icon="el-icon-mobile-phone"
              @keyup.enter.native="submitForm"
            >
              <el-button
                slot="suffix"
                type="text"
                size="mini"
                style="border: none"
                @click="sendcode"
                :disabled="!show"
              >
                获取验证码
                <span v-show="!show" class="count">({{ count }}s)</span>
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="type == 2 || type == 3 || type == 4">
            <el-input
              type="text"
              v-model="ruleForm.code"
              autocomplete="off"
              placeholder="验证码"
              prefix-icon="el-icon-folder-checked"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item prop="pass" v-if="type == 1 || type == 3 || type == 4">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
              placeholder="密码"
              prefix-icon="el-icon-lock"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" v-if="type == 3 || type == 4">
            <el-input
              type="password"
              placeholder="再次输入密码"
              v-model="ruleForm.checkPass"
              autocomplete="off"
              prefix-icon="el-icon-lock"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="type == 3">
            <el-input
              placeholder="邀请码"
              v-model="ruleForm.invitation_code"
              autocomplete="off"
              prefix-icon="el-icon-position"
              @keyup.enter.native="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div
              class="login_box_cont_btn"
              v-if="type == 1 || type == 2"
              @click="submitForm"
            >
              登录
            </div>
            <div class="login_box_cont_btn" v-if="type == 3" @click="submitForm">
              注册
            </div>
            <div class="login_box_cont_btn" v-if="type == 4" @click="submitForm">
              确定修改
            </div>
            <!-- <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>
          <el-form-item v-if="type == 1 || type == 2 || type == 3">
            <div class="flex align-center">
              <el-checkbox v-model="checked"
                >请阅读并同意<span class="text-FF524C" @click="gorichtext(2)">《服务协议》</span>和<span
                  class="text-FF524C"
                  @click="gorichtext(3)" >《隐私政策》</span
                ></el-checkbox
              >
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex align-center justify-between" v-if="type == 1 || type == 2">
              <div
                class="font-14 text-333333 cursorp"
                v-if="type == 2"
                @click="cuttype(1)"
              >
                账号密码登录
              </div>
              <div
                class="font-14 text-333333 cursorp"
                v-if="type == 1"
                @click="cuttype(2)"
              >
                验证码登录
              </div>
              <div class="font-14 text-333333 cursorp" @click="cuttype(3)">注册</div>
              <div class="font-14 text-333333 cursorp" @click="cuttype(4)">忘记密码</div>
            </div>
            <div class="font-14 text-333333 text-center" v-if="type == 3">
              已有账号前<span class="text-FF524C cursorp" @click="cuttype(2)"
                >去登录</span
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../utils/req";
import local from "@/utils/local";
export default {
  data() {
    var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    var validatephone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!reg_tel.test(value)) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      checked: false,
      typetext: "",
      type: 2,
      count: "", //倒计时
      show: true, //控制按钮
      ruleForm: {
        phone: "",
        phone2: "",
        code: "",
        pass: "",
        checkPass: "",
        invitation_code: "",
      },
      rules: {
        phone: [{ validator: validatephone, trigger: "blur" }],
        phone2: [{ validator: validatephone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let type = this.$route.query.type;
        if (type == "loginpass") {
          this.typetext = type;
          this.type = 4;
        } else {
          this.type = 2;
        }
      },
      deep: true,
    },
  },
  mounted() {
    let type = this.$route.query.type;
    if (type == "loginpass") {
      this.typetext = type;
      this.type = 4;
    } else {
      this.type = 2;
    }
  },
  methods: {
    gorichtext(id) {
      this.$router.push({
        path: "/richtext3",
        query: {  foottype: id, type: "richtext3"},
      });
    },
    // 切换type
    cuttype(id) {
      this.type = id;
      this.resetForm("ruleForm");
      this.show = true;
      clearInterval(this.timer); // 清除定时器
      this.timer = null;
      this.count = "";
    },
    //登录提交
    submitForm() {
      let that = this;
      that.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (that.type == 1) {
            if(!that.checked){
              that.$message.warning('请阅读并同意《服务协议》和《隐私政策》');
              return
            }
            req
              .post("auth/login", {
                mobile: that.ruleForm.phone,
                password: that.ruleForm.pass,
              })
              .then((res) => {
                if (res.code != 200) {
                  that.$message.error(res.msg);
                  return;
                }
                that.$message.success(res.msg);
                that.count = ""; //倒计时
                that.show = true; //控制按钮
                window.localStorage.setItem("mobile", res.data.mobile);
                window.localStorage.setItem("token", res.data.token);
                window.localStorage.setItem("user_level_id", res.data.user_level_id);
                window.localStorage.setItem("uid", res.data.id);
                that.$router.push({
                  path: "/indexer",
                  query: { type: "nav", navid: 1 },
                });
                setTimeout(() => {
                  that.$router.go(0);
                }, 600);
              })
              .catch((err) => {
                console.log(err);
                that.$message.error(err.msg);
              });
          } else if (that.type == 2) {
            if(!that.checked){
              that.$message.warning('请阅读并同意《服务协议》和《隐私政策》');
              return
            }
            req
              .post("auth/loginCode", {
                mobile: that.ruleForm.phone2,
                code: that.ruleForm.code,
              })
              .then((res) => {
                if (res.code != 200) {
                  that.$message.error(res.msg);
                  return;
                }
                that.$message.success(res.msg);
                that.count = ""; //倒计时
                that.show = true; //控制按钮
                window.localStorage.setItem("mobile", res.data.mobile);
                window.localStorage.setItem("token", res.data.token);
                window.localStorage.setItem("user_level_id", res.data.user_level_id);
                window.localStorage.setItem("uid", res.data.id);
                that.$router.push({
                  path: "/indexer",
                  query: { type: "nav", navid: 1 },
                });
                setTimeout(() => {
                  that.$router.go(0);
                }, 600);
              })
              .catch((err) => {
                console.log(err);
                that.$message.error(err.msg);
              });
          } else if (that.type == 3) {
            if(!that.checked){
              that.$message.warning('请阅读并同意《服务协议》和《隐私政策》');
              return
            }
            req
              .post("auth/register", {
                mobile: that.ruleForm.phone2,
                code: that.ruleForm.code,
                password: that.ruleForm.pass,
                password_sure: that.ruleForm.checkPass,
                invitation_code: that.ruleForm.invitation_code,
              })
              .then((res) => {
                if (res.code != 200) {
                  that.$message.error(res.msg);
                  return;
                }
                that.count = ""; //倒计时
                that.show = true; //控制按钮
                that.$message.success(res.msg);
                that.type = 2;
                that.resetForm("ruleForm");
              })
              .catch((err) => {
                console.log(err);
                that.$message.error(err.msg);
              });
          } else if (that.type == 4) {
            req
              .post("auth/forgetPwd", {
                mobile: that.ruleForm.phone2,
                code: that.ruleForm.code,
                password: that.ruleForm.pass,
                password_sure: that.ruleForm.checkPass,
              })
              .then((res) => {
                if (res.code != 200) {
                  that.$message.error(res.msg);
                  return;
                }
                that.count = ""; //倒计时
                that.show = true; //控制按钮
                that.$message.success(res.msg);
                that.type = 2;
                local.clear();
                this.$router.go(0);
                that.resetForm("ruleForm");
              })
              .catch((err) => {
                console.log(err);
                that.$message.error(err.msg);
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //发送验证码
    sendcode() {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      let that = this;
      if (!that.ruleForm.phone2) {
        that.$message.error("请输入手机号");
        return;
      } else if (!reg_tel.test(that.ruleForm.phone2)) {
        that.$message.error("请输入正确手机号");
        return;
      }
      let type = 1;
      if (that.type == 2) {
        type = 2;
      } else if (this.type == 3) {
        type = 1;
      } else if (this.type == 4) {
        type = 2;
      }
      req
        .post("auth/getCode", {
          type: type,
          mobile: that.ruleForm.phone2,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          this.$message.success(res.msg);
          if (!that.timer) {
            that.count = 60;
            that.show = false;
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= 60) {
                that.count--;
              } else {
                that.show = true;
                clearInterval(that.timer); // 清除定时器
                that.timer = null;
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  &_box {
    background-image: url("../assets/img/loginbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 6.4375rem 31.5rem;
    &_cont {
      padding: 3.125rem 12.25rem;
      background: #ffffff;
      border-radius: 0.5625rem;
      &_title {
        margin-bottom: 2.75rem;
        font-size: 1.25rem;
        color: #333333;
        text-align: center;
      }
      &_btn {
        width: 100%;
        height: 2.75rem;
        line-height: 2.75rem;
        background: #ff524c;
        border-radius: 1.375rem;
        text-align: center;
        color: #ffffff;
        font-size: 0.875rem;
        margin-bottom: 1.25rem;
        cursor: pointer;
      }
      ::v-deep .el-button--text {
        border-color: transparent;
        color: #222222;
        background: 0 0;
        padding-left: 0;
        padding-right: 0;
      }
      ::v-deep .el-input__inner {
        -webkit-appearance: none;
        border: 0;
        box-sizing: border-box;
        color: #000000;
        display: inline-block;
        font-size: inherit;
        line-height: 2.75rem;
        outline: 0;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        height: 2.75rem;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 1.375rem;
      }
      ::v-deep input::-webkit-outer-spin-button,
      ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      ::v-deep input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
</style>
